export const APP_ENV = process.env.NODE_ENV;
export const NOTHING_TO_SHOW = "Nothing to show";

//User Config constants
export const FETCH_CONFIG_DETAILS = "FETCH_CONFIG_DETAILS";
export const RESET_CONFIG_DETAILS = "RESET_CONFIG_DETAILS";
export const FETCH_CONFIG_DETAILS_SUCCESS = "FETCH_CONFIG_DETAILS_SUCCESS";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const FETCH_CONFIG_DETAILS_FAILURE = "FETCH_CONFIG_DETAILS_FAILURE";

export const SESSION_EXPIRED = "Your session has expired";
export const FORBIDDEN = "You don't have permission to access";
export const SERVER_ERROR_MESSAGE = "Internal Server Error, Contact Administrator";

export const FETCH_TIMELINE = "FETCH_TIMELINE";
export const FETCH_TIMELINE_SUCCESS = "FETCH_TIMELINE_SUCCESS";
export const FETCH_TIMELINE_FAILURE = "FETCH_TIMELINE_FAILURE";
export const RESET_TIMELINE = "RESET_TIMELINE";

export const GENERIC_ERROR_EMPTY_STATE = "Something went wrong. Please try again later";
export const ACCESS_POLICY_EMPTY_STATE = "Access policy not available.";

//Dataset Archiving Constant
export const ARCHIVE_DATASET = "ARCHIVE_DATASET";
export const ARCHIVE_DATASET_SUCCESS = "ARCHIVE_DATASET_SUCCESS";
export const ARCHIVE_DATASET_FAILURE = "ARCHIVE_DATASET_FAILURE";
export const ARCHIVE_DATASET_DONE = "Dataset archived successfully.";
export const ARCHIVE_DATASET_FAIL = "Something went wrong. Please try again.";

// Global Policy.
export const FETCH_ACCESS_POLICY = "FETCH_ACCESS_POLICY";
export const FETCH_ACCESS_POLICY_SUCCESS = "FETCH_ACCESS_POLICY_SUCCESS";
export const FETCH_ACCESS_POLICY_FAILURE = "FETCH_ACCESS_POLICY_FAILURE";

export const FETCH_LOCAL_POLICY = "FETCH_LOCAL_POLICY";
export const FETCH_LOCAL_POLICY_SUCCSS = "FETCH_LOCAL_POLICY_SUCCSS";
export const FETCH_LOCAL_POLICY_FAILURE = "FETCH_LOCAL_POLICY_FAILURE";
export const FETCH_FILTER_POLICY_SUCCESS = "FETCH_FILTER_POLICY_SUCCESS";
export const FETCH_FILTER_POLICY_FAILURE = "FETCH_FILTER_POLICY_FAILURE";
export const FETCH_MASK_POLICY_SUCCESS = "FETCH_MASK_POLICY_SUCCESS";
export const FETCH_MASK_POLICY_FAILURE = "FETCH_MASK_POLICY_FAILURE";

export const FETCH_GLOBAL_FILTER_POLICY = "FETCH_GLOBAL_FILTER_POLICY";
export const FETCH_GLOBAL_FILTER_POLICY_SUCCESS =
  "FETCH_GLOBAL_FILTER_POLICY_SUCCESS";
export const FETCH_GLOBAL_FILTER_POLICY_FAILURE =
  "FETCH_GLOBAL_FILTER_POLICY_FAILURE";

export const FETCH_GLOBAL_POLICY = "FETCH_GLOBAL_POLICY";
export const FETCH_GLOBAL_POLICY_SUCCESS = "FETCH_GLOBAL_POLICY_SUCCESS";
export const FETCH_GLOBAL_POLICY_FAILURE = "FETCH_GLOBAL_POLICY_FAILURE";

export const FETCH_GLOBAL_MASK_POLICY = "FETCH_GLOBAL_MASK_POLICY";
export const FETCH_GLOBAL_MASK_POLICY_SUCCESS =
  "FETCH_GLOBAL_MASK_POLICY_SUCCESS";
export const FETCH_GLOBAL_MASK_POLICY_FAILURE =
  "FETCH_GLOBAL_MASK_POLICY_FAILURE";

// constant for user applicable filter policy.
export const FETCH_USER_FILTER_POLICY = "FETCH_USER_FILTER_POLICY";
export const FETCH_USER_FILTER_POLICY_SUCCESS =
  "FETCH_USER_FILTER_POLICY_SUCCESS";
export const FETCH_USER_FILTER_POLICY_FAILURE =
  "FETCH_USER_FILTER_POLICY_FAILURE";

export const FETCH_USER_MASK_POLICY = "FETCH_USER_MASK_POLICY";
export const FETCH_USER_MASK_POLICY_SUCCESS = "FETCH_USER_MASK_POLICY_SUCCESS";
export const FETCH_USER_MASK_POLICY_FAILURE = "FETCH_USER_MASK_POLICY_FAILURE";

export const MESSAGES_COMMON_ERROR = " Something went wrong. Please try again.";


export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS"
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";
export const APPS_DIRECTORY_MESG = 'Some issue in deployed apps please explore these apps: ';
export const ROOT_COOKIE_NAME = "dos.root";

export const FETCH_APPS_DETAILS = "FETCH_APPS_DETAILS";
export const FETCH_APPS_DETAILS_SUCCESS = "FETCH_APPS_DETAILS_SUCCESS"
export const FETCH_APPS_DETAILS_FAILURE = "FETCH_APPS_DETAILS_FAILURE";

export const UPDATE_AUTHORIZED_ATOMS = "UPDATE_AUTHORIZED_ATOMS";
export const ACCESS_DENIED_MESSAGE = "You don't have access, please contact your administrator.";

export const TENANT_PREFIX = 'tenants/'

export const TENANT_BASE_URL = 'api/tenants'
