import React from "react";
import { Tag, Space, Typography, Divider, Tooltip, Row, Image } from "antd";
import { RenderAvatar } from "../UpdateUserProfile/RenderAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { ProfileDetailType } from "../../containers/AppContainer";
import { userDetailType } from "./RenderUserProfileEditMode";
import OpenNew from "../../assets/OpenInNew-outlined.svg";

const { Paragraph, Title, Text } = Typography;

interface IProps {
  setAvatar: (avatar: string) => void;
  userDetail?: userDetailType;
  isCurrentUser: boolean;
  profileDetail: ProfileDetailType;
  setProfileDetail: React.Dispatch<React.SetStateAction<ProfileDetailType>>;
}

export const UserSidebar = (props: IProps) => {
  const { userDetail } = props;
  const localStorageTenant = localStorage.getItem("tenant");
  const tenantValue = localStorageTenant && JSON.parse(localStorageTenant);
  interface PropertiesType {
    key: string;
    value: string;
  }
  const renderLink = (
    arr?: Array<PropertiesType> | null,
    text?: string,
    icon?: IconDefinition
  ) => {
    let obj = arr!.find((o: PropertiesType) => {
      if (o.key === text) {
        return o.value;
      }
      return null;
    });

    if (obj) {
      if (text === "location") {
        return <span>{obj.value}</span>;
      } else if (text === "jobTitle") {
        return <span>{obj.value}</span>;
      } else {
        return (
          <Tooltip title={obj.value}>
            <a
              href={obj.value}
              target={"_blank"}
              className="d-inline-block mr-2"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon color="#808080" icon={icon!} />
            </a>
          </Tooltip>
        );
      }
    }
  };

  const filterByValue = (array: any, string: string) => {
    return array
      ? array.filter((obj: any) => JSON.stringify(obj).indexOf(string) !== -1)
      : "";
  };

  const description = filterByValue(userDetail?.user?.properties, "bio");
  const isThereAnySocialLink = () => {
    let socialLinkCount = 0;
    if (filterByValue(userDetail?.user?.properties, "linkedin").length > 0) {
      socialLinkCount += 1;
    } else if (
      filterByValue(userDetail?.user?.properties, "github").length > 0
    ) {
      socialLinkCount += 1;
    } else if (
      filterByValue(userDetail?.user?.properties, "website").length > 0
    ) {
      socialLinkCount += 1;
    }
    return socialLinkCount > 0 ? true : false;
  };

  return (
    <div style={{ padding: 12 }}>
      <RenderAvatar
        avatar={userDetail!.avatars!.image_base64}
        userName={userDetail!.user!.name}
        userId={userDetail!.user!.id}
        {...props}
      />
      <Divider style={{ margin: "16px 0px" }} />
      <Space direction="vertical">
        <Paragraph strong style={{ fontSize: "16px", margin: "0" }}>
          {userDetail!.user!.name}
        </Paragraph>
        <Paragraph style={{ wordBreak: "break-word" }}>
          {userDetail!.user!.email}
        </Paragraph>
        <Paragraph style={{ marginTop: "-10px" }}>
          {description &&
          description.length > 0 &&
          description[0]["value"].length > 0
            ? description[0]["value"]
            : "No description"}
        </Paragraph>
      </Space>
      <Space direction="vertical">
        <div>
          <Text style={{ fontWeight: 500 }}>Id</Text>
          <Divider style={{ margin: "8px 0px" }} />
          <Paragraph style={{ wordBreak: "break-word" }}>
            {userDetail!.user!.id}
          </Paragraph>
        </div>
        <div>
          <Text style={{ fontWeight: 500 }}>Type</Text>
          <Divider style={{ margin: "8px 0px" }} />
          <Paragraph>{userDetail!.user!.type}</Paragraph>
        </div>
        <div>
          {isThereAnySocialLink() && (
            <>
              <Text style={{ fontWeight: 500 }}>Social</Text>
              <Divider style={{ margin: "8px 0px" }} />
              <Space align="center" style={{ width: "100%" }}>
                {renderLink(
                  userDetail!.user!.properties,
                  "linkedin",
                  faLinkedin
                )}
                {renderLink(userDetail!.user!.properties, "github", faGithub)}
                {renderLink(userDetail!.user!.properties, "website", faGlobe)}
              </Space>
            </>
          )}
        </div>
        <div>
          <>
            <Text style={{ fontWeight: 500 }}>Tags</Text>
            <Divider style={{ margin: "8px 0px" }} />
            <Space wrap={true} style={{ marginTop: "5px" }}>
              {userDetail?.user?.tags &&
                userDetail?.user?.tags?.map((tag: string, i: number) => {
                  return (
                    <Tag key={tag + i} style={{ margin: "0" }}>
                      {tag}
                    </Tag>
                  );
                })}
            </Space>
          </>
        </div>
        <Row style={{ marginTop: 10 }}>
          <Space wrap={true}>
            {userDetail?.user?.id && (
              <a
                href={`/metis/${tenantValue}/users/${userDetail?.user?.id}`}
                target="_blank"
                rel="noreferrer"
                className="open-to-metis-underline"
              >
                Open in Metis
                <Image
                  height={16}
                  width={16}
                  preview={false}
                  src={OpenNew}
                  style={{ marginLeft: "3px", marginTop: "-3px" }}
                />
              </a>
            )}
          </Space>
        </Row>
      </Space>
    </div>
  );
};
