import React, { useState, useEffect } from "react";
import Tokens from "./Tokens";
import { App, Tabs, Row, Col, Grid, Divider } from "antd";
import Loading from "../../common/loader";
import { useLocation, useParams, Navigate } from "react-router-dom";
import { PropertiesType, UpdateUserProfile } from "../UpdateUserProfile";
import { UserSidebar } from "./UserSidebar";
import { api } from "../../utils/fetch-json";
import TimeLineView from "../TimeLineView";
import { ProfileDetailType } from "../../containers/AppContainer";
import {
  RootStateOrAny,
  shallowEqual,
  useSelector,
  useDispatch,
} from "react-redux";
import { fetchUserDetails } from "../../actions/UserDetailsAction";

const { useBreakpoint } = Grid;
interface IProps {
  setProfileDetail: React.Dispatch<React.SetStateAction<ProfileDetailType>>;
  profileDetail: ProfileDetailType;
  isCurrentUser: boolean;
}
interface userType {
  email?: string;
  id: string;
  name?: string;
  federated_connector_id?: string | null;
  federated_user_id?: string | null;
  properties?: Array<PropertiesType> | null;
  tags?: Array<string> | null;
  type?: string;
}
export interface userDetailType {
  user?: userType;
  tokens?: string[] | null;
  avatars?: { image_base64?: string };
}
export interface userTokensType {
  data: { apikey: string };
  expiration: string;
  name: string;
  type: string;
  user_id: string;
  _links?: { self: string; user: string };
}
const RenderUserProfileEditMode = (props: IProps) => {
  const [userDetail, setUserDetail] = useState<userDetailType | null>(null);
  const [userTokens, setUserTokens] = useState<userTokensType[]>(
    [] as userTokensType[]
  );
  const [loader, setloader] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const subSection = queryParams.get("section");
  const [activeKey, setActiveKey] = useState(
    subSection ? subSection : "overview"
  );
  const params = useParams<{ userId: string }>();
  const { setProfileDetail, profileDetail, isCurrentUser } = props;
  const screens = useBreakpoint();
  const { notification } = App.useApp();

  const dispatch = useDispatch();
  const { userId } = params;

  useEffect(() => {
    document.title = `Profile: ${
      activeKey.charAt(0).toUpperCase() + activeKey.slice(1)
    } | DataOS`;
    // TO-DO: @Vivek : What should put as title
    // document.title = `${(profileDetail.currentUser.name)} | DataOS`
  }, [activeKey]);

  const onClickTab = (key: string) => {
    if (activeKey !== key) {
      setActiveKey(key);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("section", key);
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  };

  const loginWithRedirection = () => {
    let redirectUrl = window.location.href;
    return <Navigate to={`/`} />;
  };

  const handleError = (error: any) => {
    if (error.response.status === 401) {
      loginWithRedirection();
    } else if (error.response.status === 403) {
      notification.warning({
        message: `Access denied`,
        placement: "bottomRight",
      });
    }
  };

  const { user, config, userDetails, userDetailsError, features } = useSelector(
    (state: RootStateOrAny) => ({
      user: state.commonReducer.user,
      config: state.commonReducer.config,
      userDetails: state.userDetailsReducer.userDetails,
      userDetailsError: state.userDetailsReducer.error,
      features: state.commonReducer.features,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (config) {
      dispatch(fetchUserDetails(userId));
    }
  }, [config]);

  useEffect(() => {
    if (userDetails) {
      setUserDetail(userDetails);
      setUserTokens(userDetails.tokens);
      setloader(false);
    } else if (userDetailsError) {
      handleError(userDetailsError);
    }
  }, [userDetails, userDetailsError]);

  const updateToken = (type: string, token: string) => {
    let arr = userTokens;
    if (type === "delete") {
      arr = arr!.filter((obj: userTokensType) => obj.name !== token);
      setUserTokens(arr);
      notification.success({
        message: `Token deleted`,
        placement: "bottomRight",
      });
    } else if (type === "add") {
      // var newArr = [token].concat(arr);
      var newArr = [...arr!, token] as userTokensType[];
      setUserTokens(newArr);
      notification.success({
        message: `Token added`,
        placement: "bottomRight",
      });
    }
  };

  const setAvatar = (avatar: string) => {
    setUserDetail({ ...userDetail, avatars: { image_base64: avatar } });
  };

  const updateSideBarUserDetail = (updatedUserData: userDetailType) => {
    setUserDetail(updatedUserData);
  };

  const items = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <>
          {" "}
          {userDetail && (
            <UpdateUserProfile
              userDetail={userDetail}
              //setUserDetail={setUserDetail}
              //refetchUserDetails={getUserDetails}
              setProfileDetail={setProfileDetail}
              profileDetail={profileDetail}
              isCurrentUser={isCurrentUser}
              updateSideBarUserDetail={updateSideBarUserDetail}
            />
          )}
        </>
      ),
    },
    {
      key: "tokens",
      label: "Tokens",
      children: <Tokens data={userTokens} updateToken={updateToken} />,
    },
  ];

  if (features && features.homeProvenanceEnable === true) {
    const PROVENANCE = {
      key: "provenance",
      label: "Provenance",
      children: <TimeLineView />,
    };
    items.push(PROVENANCE);
  }

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <div className="user-profile">
          <Row style={{ position: "relative" }} wrap={false}>
            <Col flex="255px" id="catalog_left_section">
              {userDetail && (
                <UserSidebar
                  setAvatar={setAvatar}
                  userDetail={userDetail}
                  {...props}
                />
              )}
            </Col>
            {screens.sm && screens.md && (
              <Col flex="48px">
                <Divider
                  type="vertical"
                  style={{ height: "100%", margin: "0 0" }}
                />
              </Col>
            )}
            <Col flex="auto" id="catalog_right_section">
              <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={onClickTab}
                key={location.key}
                items={items}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default RenderUserProfileEditMode;
