import React, { SetStateAction } from "react";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ProfileDetailType } from "../../containers/AppContainer";
import RenderUserProfileEditMode from "./RenderUserProfileEditMode";
import RenderUserProfileViewMode from "./RenderUserProfileViewMode";

interface IProps {
  profileDetail: ProfileDetailType;
  setProfileDetail: React.Dispatch<SetStateAction<ProfileDetailType>>;
}
export default function UserProfile(props: IProps) {
  const params = useParams<{ userId: string }>();
  const { user } = useSelector(
    (state: RootStateOrAny) => ({
      user: state.commonReducer.user,
    }),
    shallowEqual
  );

  let userIDLocal = JSON.parse(localStorage.getItem("userInfo") ?? "{}");

  const isCurrentUser = params.userId === userIDLocal!.id ? true : false;
  return isCurrentUser ? (
    <RenderUserProfileEditMode isCurrentUser={isCurrentUser} {...props} />
  ) : (
    <RenderUserProfileViewMode isCurrentUser={isCurrentUser} {...props} />
  );
}
