import React, { useState } from "react";
import { RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { Dropdown, Typography, MenuProps } from "antd";
import { useAuth, useLocalStorage, clearLocalStorageKey } from "modern-auth";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils/Utils";
import { ROOT_COOKIE_NAME } from "../../utils/Const";

interface Iprops {
  toggleTheme: () => void;
}

const UserInfo = (props: Iprops) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useLocalStorage("userInfo", "");

  const localStorageTenant = localStorage.getItem("tenant");
  const tenantValue = localStorageTenant && JSON.parse(localStorageTenant);

  const { appVersion, installVersion } = useSelector(
    (state: RootStateOrAny) => ({
      appVersion: state.commonReducer.appVersion,
      installVersion: state.commonReducer.installVersion,
    }),
    shallowEqual
  );

  const signOut = async () => {
    if (auth.isAuthenticated) {
      auth.removeUser();
      Utils.deleteCookie(ROOT_COOKIE_NAME);
      // localStorage.removeItem("userInfo");
      clearLocalStorageKey("userInfo");
    }
    navigate(`/`);
  };

  const items: MenuProps["items"] = [
    {
      key: "user",
      label: (
        <a
          href={`${window.location.origin}/home/${tenantValue}/profile/${value.id}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "unset" }}
        >
          {value.name}
          <div>
            <small>{value.email}</small>
          </div>
        </a>
      ),
    },
    { key: "user-divider", type: "divider" },
    {
      key: "log_out",
      label: (
        <>
          <div onClick={() => signOut()}>
            <span key="1" className={"userInfo"}>
              <small>{"Log out"}</small>
            </span>
          </div>
        </>
      ),
    },
    { key: "log_out-divider", type: "divider" },
    {
      key: "toggle_theme",
      label: <>{props.toggleTheme}</>,
    },
    { key: "toggle_theme-divider", type: "divider" },
    {
      key: "versions",
      label: (
        <div style={{ cursor: "none" }}>
          {appVersion && (
            <Typography.Text disabled>
              <small>{installVersion}</small>
              <small style={{ display: "block" }}>{appVersion}</small>
            </Typography.Text>
          )}
        </div>
      ),
    },
  ];

  if (value) {
    return (
      <>
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          overlayClassName="user-info-dropdown"
          getPopupContainer={() => {
            return document.body;
          }}
          destroyPopupOnHide={true}
          // overlayStyle={{ width: "190px" }}
        >
          <Avatar
            name={value?.name}
            size="30"
            maxInitials={2}
            src={`data:image/png;base64, ${value?.avatar_base64}`}
            round={true}
            color={"#4a8ac3"}
            className="pointer"
          />
        </Dropdown>
      </>
    );
  } else {
    return <> no data </>;
  }
};

export default React.memo(UserInfo);
