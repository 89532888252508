import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import Loader from "../common/loader";
import { Layout, Grid, ConfigProvider, theme, App } from "antd";
import * as commonAction from "../actions/CommonAction";
import NothingToShow from "../common/NothingToShow";
import {
  fetchHeimdallUserDetails,
  useAuth,
  useLocalStorage,
  clearLocalStorageKey,
} from "modern-auth";
import Utils from "../utils/Utils";
import {
  ROOT_COOKIE_NAME,
  TENANT_BASE_URL,
  TENANT_PREFIX,
} from "../utils/Const";
import axios from "axios";
import { useAnalyticsContext } from "modern-analytics";
import RenderPage from "./RenderPage";

export type configType = {
  baseUrl: string;
  logLevel: string;
  publicUrl: string;
  porosBaseUrl: string;
  blenderBaseUrl: string;
  caretakerBaseUrl: string;
};

const { useBreakpoint } = Grid;
const { Content } = Layout;

type Theme = "light" | "dark";
export type currentUserType = {
  id?: string | null;
  email?: string;
  name?: string;
  avatar_base64?: string | null;
};
export type ProfileDetailType = {
  currentUser?: currentUserType | null;
  config?: configType | null;
  installVersion?: string;
  name?: string;
  version?: string;
  features?: { any } | null;
  [x: string]: any;
} | null;

const systemTheme = () =>
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

const getTheme = () =>
  (localStorage.getItem("theme") as Theme) || systemTheme();

const AppContainer = (props: { authInfoUrl: string }) => {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [loader, setLoader] = useState(true);
  const [profileDetail, setProfileDetail] = useState<ProfileDetailType>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState<string | null>(null);
  const localStorageTenant = localStorage.getItem("tenant");
  const localStorageTenantVal =
    localStorageTenant && JSON.parse(localStorageTenant);
  const [tenantValue, setTenantValue] = useState<any>(
    localStorageTenantVal || null
  );
  const [tenantOptions, setTenantOptions] = useState<any>([]);

  const screens = useBreakpoint();

  const [value, setValue] = useLocalStorage("userInfo", "");
  const auth: any = useAuth();

  const [currentTheme, setCurrentTheme] = useState<Theme>(
    localStorage.getItem("theme") as Theme
  );

  const setTheme = (theme: string) => {
    localStorage.setItem("theme", theme);
    document.body.setAttribute("class", theme);
    setIsDarkMode(theme === "dark" ? true : false);
  };

  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  const { user, config, installVersion, features, loading, isUnauthorized } =
    useSelector(
      (state: RootStateOrAny) => ({
        user: state.commonReducer.user,
        config: state.commonReducer.config,
        installVersion: state.commonReducer.appVersion,
        features: state.commonReducer.features,
        loading: state.commonReducer.loading,
        isUnauthorized: state.commonReducer.isUnauthorized,
      }),
      shallowEqual
    );
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.setAttribute("class", currentTheme);
  }, [currentTheme]);

  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
    if (!isDarkMode) {
      localStorage.setItem("theme", "dark");
      document.body.setAttribute("class", "dark");
    } else {
      localStorage.setItem("theme", "light");
      document.body.setAttribute("class", "light");
    }
  };

  const loadAuth = async () => {
    const response = await fetchHeimdallUserDetails(auth.user.access_token);
    if (response.status === 200) {
      setValue(response.data);
      Utils.setCookie(ROOT_COOKIE_NAME, auth.user.access_token);
    } else {
      auth.removeUser();
      clearLocalStorageKey("userInfo");
      setMessage("Unauthorized");
      Utils.deleteCookie(ROOT_COOKIE_NAME);
    }

    if (auth.user && auth.user.state && auth.user.state.targetHref) {
      let redirectUrl =
        "/" + auth.user.state.targetHref.split("/").slice(2).join("/");
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    setTheme(getTheme());
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated && !value) {
      loadAuth();
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (tenantValue && tenantValue !== localStorageTenantVal) {
      localStorage.setItem("tenant", JSON.stringify(tenantValue));
    }
  }, [tenantValue]);

  useEffect(() => {
    if (isUnauthorized && auth.isAuthenticated) {
      auth.removeUser();
      Utils.deleteCookie(ROOT_COOKIE_NAME);
      localStorage.removeItem("userInfo");
      localStorage.removeItem("tenant");
    }
  }, [isUnauthorized]);

  const getTenants = async () => {
    await axios
      .get(TENANT_BASE_URL, {
        headers: {
          Authorization: `Bearer ${value.accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const tenantData = data.map((each) => ({
          value: `${TENANT_PREFIX}${each.id}`,
          label: each.id,
        }));
        setTenantOptions(tenantData);
        if (!tenantValue && tenantData.length > 0) {
          const path = tenantData[0]?.value;
          setTenantValue(path);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useLayoutEffect(() => {
    value.accessToken && getTenants();
  }, [value.accessToken]);

  const fetchConfigDetails = async () => {
    dispatch(commonAction.fetchConfigDetails());
    setProfileDetail({
      currentUser: user,
      config: config,
      installVersion: installVersion,
      name: user?.name,
      version: installVersion,
      features: features,
    });
    setLoader(false);
  };

  const getAuthorizedAtoms = () => {
    let url = "api/fetchAuthorizeAtoms";
    axios
      .get(url)
      .then((res) => {
        dispatch(commonAction.updateAuthorizedAtoms(res.data.results));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${value.accessToken}`;
    if (value) {
      fetchConfigDetails();
      getAuthorizedAtoms();
    }
  }, [value]);

  useEffect(() => {
    const checkLocalstorage = (event: StorageEvent) => {
      if (
        event.key === "userInfo" &&
        (event.newValue === null || event.oldValue === null)
      ) {
        (event.currentTarget as Window).location.reload();
      }
    };
    window.addEventListener("storage", checkLocalstorage);
    return () => {
      window.removeEventListener("storage", checkLocalstorage);
    };
  }, []);

  const { analytics, setAnalyiticDetails } = useAnalyticsContext();
  const location = useLocation();

  useEffect(() => {
    setAnalyiticDetails({
      appName: "Home App",
      appVersion: installVersion,
      analyticsUrl: config?.analyticsUrl,
      userId: user?.id ?? "",
      accessToken: `Bearer ${value?.accessToken}`,
    });
  }, [user, installVersion]);

  const handleClickEvent = useCallback(
    (event) => {
      const eventName = event.target?.textContent || "Click";
      if (eventName) {
        analytics?.track("Home app click", { target: eventName });
      }
    },
    [analytics]
  );
  useEffect(() => {
    const { pathname } = location;
    // except default page
    if (pathname !== "/") {
      // track page view on route change
      analytics?.page();
    }
  }, [location.pathname]);

  useEffect(() => {
    const targetNode = document.body;
    targetNode.addEventListener("click", handleClickEvent);

    return () => targetNode.removeEventListener("click", handleClickEvent);
  }, [handleClickEvent]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
      getPopupContainer={(node) =>
        node ? (node.parentNode as HTMLDivElement) : document.body
      }
    >
      <App>
        <Scrollbars
          style={{
            height: "100vh",
            position: "absolute",
          }}
          renderView={(props) => (
            <div
              {...props}
              style={{
                ...props.style,
                overflowX: "hidden",
                marginBottom: 0,
              }}
              id="app-scrollbar"
            />
          )}
          autoHide
        >
          <Layout style={{ minHeight: "100%" }} className="container-layout">
            {Object.keys(screens).length > 0 && !screens.lg && (
              <NothingToShow
                text={"Current screen resolution not supported."}
                padding={"0 20px"}
                type="alert"
              />
            )}
            {loading ? (
              <Content
                style={{
                  padding: "20px",
                  height: "100%",
                  width: "100%",
                  maxWidth: 1440,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Loader padding="0px" />
              </Content>
            ) : (
              <RenderPage
                auth={auth}
                message={message}
                value={value}
                toggleTheme={
                  <small onClick={handleClick} style={{ fontSize: "11px" }}>
                    Switch to {isDarkMode ? "light" : "dark"} {"mode(βeta)"}
                  </small>
                }
                user={user}
                currentTheme={currentTheme}
                profileDetail={profileDetail}
                setProfileDetail={setProfileDetail}
                setNotFound={setNotFound}
                features={features}
                tenantValue={tenantValue}
                setTenantValue={setTenantValue}
                tenantOptions={tenantOptions}
              />
            )}
          </Layout>
        </Scrollbars>
      </App>
    </ConfigProvider>
  );
};

export default AppContainer;
